import './App.css';

import { ReactLenis, useLenis } from 'lenis/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from './pages/Root';
import Landing from './pages/Landing';
import Solution from './pages/Solution';
import Studio from './pages/Studio';
import Work from './pages/Work';
import Contact from './pages/Contact';
import Error from './pages/Error';
import ProjectPage from './components/ProjectPage';
// import Services from './pages/Services';

import { TranslationProvider } from './hooks/TranslationProvider';

const router = createBrowserRouter([
  { path: '/',  
  
    element: <RootLayout />,

    errorElement: <Error />,
  
    children: [
      { index: true,  element: <Landing />},
      { path: 'solutions',  element: <Solution />},
      { path: 'studio',  element: <Studio />},

      { path: 'work',  element: <Work />},
      //Studio sub-routes start
      // Single route for all project pages
      { path: 'work/:projectId', element: <ProjectPage />},
      //Studio sub-routes end
      
      // { path: 'services', element: <Services /> },
      { path: 'contact',  element: <Contact />},
    ]}
  ]);

export default function App() {
  const lenis = useLenis(({ scroll }) => {
    // called every scroll
  })
  return (
    <TranslationProvider>
      <ReactLenis root>
        <RouterProvider router={router}/> 
      </ReactLenis>
    </TranslationProvider>
  );
}
