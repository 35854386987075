import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams, Navigate } from "react-router-dom";
import TranslationContext from "../hooks/TranslationProvider";
import HorizontalScroll from "../components/HorizontalScroll";
import SceneProject from "../components/SceneProjects";
import MobileProjectBento from "../components/MobileProjectBento";
import NextProject from "../components/NextProject";
import classes from "../pages/Project.module.css";

// Import project data
import projects from "../data/projects";

export default function ProjectPage() {
  const { projectId } = useParams();
  const { t } = useContext(TranslationContext);
  const isMobile = useMediaQuery({ maxWidth: "700px" });
  
  // Get project data or redirect if not found
  const project = projects[projectId];
  if (!project) {
    return <Navigate to="/work" replace />;
  }
  
  const { 
    title, 
    year, 
    translationKey, 
    videoSource, 
    imageSource, 
    photos, 
    nextProject, 
    liveUrl,
    hasHorizontalScroll
  } = project;

  return (
    <div className={classes.container}>
      <SceneProject videoSource={videoSource} imageSource={imageSource} />
      <div className="projectInfo">
        <h1 style={title.length > 15 ? { fontSize: "11rem", textWrap: "wrap" } : {}}>
          {title}
        </h1>
        <h2>{t(`work.${translationKey}.title`)}</h2>
        <h2>{year}</h2>
        <div className={classes.scrollContainer}>
          <img 
            src="/images/icons/arrow.svg" 
            alt="Scroll arrow icon" 
            className={classes.scrollContainer}
          />
          <p>{t("work.scroll")}</p>
        </div>
      </div>

      <div className="projectDetailsContainer">
        <div className="projectDetailsContainerFlex">
          <div className="projectDetailsElement">
            <h3>{t(`work.${translationKey}.summary`)}</h3>
            {t(`work.${translationKey}.descriptions`).map((description, index) => (
              <p key={index}>{description}</p>
            ))}
          </div>

          <div className="projectDetailsElement">
            <h4>{t(`work.${translationKey}.whatWeDid.title`)}</h4>
            <ol>
              {t(`work.${translationKey}.whatWeDid.items`).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ol>
          </div>

          <div className="projectDetailsElement">
            <h4>{t(`work.${translationKey}.client.title`)}</h4>
            <ol>
              <li>{t(`work.${translationKey}.client.name`)}</li>
            </ol>

            <h4 style={{ marginTop: "20px" }}>
              {t(`work.${translationKey}.techUsed.title`)}
            </h4>
            <ol>
              {t(`work.${translationKey}.techUsed.techs`).map((tech, index) => (
                <li key={index}>{tech}</li>
              ))}
            </ol>
          </div>

          {liveUrl && (
            <div className="projectDetailsElement">
              <a href={liveUrl} target="_blank" rel="noreferrer">
                <span>{t("work.liveProject")}</span>
                <svg viewBox="0 0 100 100" width="30" height="30">
                  <circle cx="50" cy="50" r="45" />
                </svg>
              </a>
            </div>
          )}
        </div>
      </div>

      {hasHorizontalScroll && photos && photos.length > 0 && (
        <div className="projectImages">
          {isMobile && <MobileProjectBento photos={photos} />}
          {!isMobile && <HorizontalScroll photos={photos} />}
        </div>
      )}

      <div className="nextProjectContainer">
        <NextProject destiny={nextProject} />
      </div>
    </div>
  );
} 
