import './StudioAbout.css';
import { useEffect, useRef, useContext } from 'react';
import gsap from 'gsap';

// import i2 from '../img/studio/pexels-nihongraphy-2-22469233-20475584.jpg';

import { ScrollTrigger } from 'gsap/ScrollTrigger';
import TranslationContext from '../hooks/TranslationProvider';

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

export default function StudioAbout(){
    const { t } = useContext(TranslationContext);
    const img1 = useRef(null);
    const img2 = useRef(null);
    const img3 = useRef(null);
    const aboutContainer = useRef(null);

    useEffect(() => {

        console.log('contactContainer:', img1.current);
        console.log('contactContainer:', img2.current);
        console.log('contactContainer:', img3.current);
        console.log('aboutUsContainer:', aboutContainer.current);

        if(img1.current && img2.current && img3.current && aboutContainer.current){
            gsap.fromTo(img1.current, 
                { y: '30%' },  // Initial position at 30%
                { 
                  y: '-15%',   // End position for the parallax effect
                  scrollTrigger: {
                    trigger: aboutContainer.current,
                    start: 'top top',
                    end: 'bottom top',
                    scrub: true,  // Smooth scrubbing
                  }
                }
              );
              
              gsap.fromTo(img2.current, 
                { y: '35%' },  // Initial position at 35%
                { 
                  y: '-5%',   // End position for the parallax effect
                  scrollTrigger: {
                    trigger: aboutContainer.current,
                    start: 'top top',
                    end: 'bottom top',
                    scrub: true,
                  }
                }
              );
              
              gsap.fromTo(img3.current, 
                { y: '50%' },  // Initial position at 50%
                { 
                  y: '-30%',   // End position for the parallax effect
                  scrollTrigger: {
                    trigger: aboutContainer.current,
                    start: 'top top',
                    end: 'bottom top',
                    scrub: true,
                  }
                }
              );
        } else{
            console.error("Refs are null");
        }
          
    }, []);
    
    return(
        <div className='about-container' ref={aboutContainer}>
            <h2 className='why-us'>{t('studio.whyUs.title')}</h2>
            
            <img className='img-1 img' src={'/images/studio/pexels-nemuel-6424589.jpg'} alt='Us' ref={img1}/>
            <img className='img-2 img' src={'/images/studio/us.png'} alt='Us' ref={img2}/>
            <img className='img-3 img' src={'/images/studio/pexels-sophors-25437425.jpg'} alt='Us' ref={img3}/>

            <h1 className='why-1 why'>{t('studio.whyUs.text1')}</h1>
            <h1 className='why-2 why'>{t('studio.whyUs.text2')}</h1>
            <h1 className='why-3 why'>{t('studio.whyUs.text3')}</h1>
            <h1 className='why-4 why'>{t('studio.whyUs.text4')}</h1>

        </div>
    );
};
