import { Link } from "react-router-dom";
import { useState, useContext } from 'react';
import TranslationContext from '../hooks/TranslationProvider';
import classes from './Work.module.css';

export default function Work() {
    const { t } = useContext(TranslationContext);
    const [filterButton, setFilterButton] = useState(false);
    const [filter, setFilter] = useState('ALL');
    // const [filter, setFilter] = useState(t('work.all'));

    const projects = [
        {
            title : 'UG MEDICAL',
            year : t('work.inProgress'),
            description: 'SOLUTIONS',
            img : '/images/studio/coming.png'
        },
        {
            title : "CINTHYA'S VANITY",
            year : 2025,
            description: 'SOLUTIONS',
            img : '/images/cinthyasvanity/cinthyasvanity-browser.png',
            to: 'cinthyasvanity'
        },
        {
            title : 'NOTARÍA 137',
            year : 2024,
            description: 'STUDIO',
            img : '/images/notaria137/notaria137-browser.png',
            to: 'notaria137'
        },
        {
            title : 'SYSNOT',
            year : 2024,
            description: 'SOLUTIONS',
            img : '/images/studio/not.png',
            to: 'sysnot'
        },
        {
            title : 'TS MORALES',
            year : 2024,
            description: 'STUDIO',
            img : '/images/studio/tsmorales.png',
            to: 'tsmorales'
        },
        {
            title : 'CERM AUTOMOTIVE',
            year : 2023,
            description: 'SOLUTIONS',
            img : '/images/studio/cerm.png',
            to: 'cerm'
        },
        {
            title : 'MERCYFIT',
            year : 2023,
            description: 'SOLUTIONS',
            img : '/images/mercy/mercyfit-browser.png',
            to: 'mercyfit'
        },
    ]

    const handleOpenFilter = () => {
        setFilterButton(() => true);
    };

    const handleCloseFilter = () => {
        setFilterButton(() => false);
    };

    const handleSelect = (description) => {
        setFilter(() => description);
        setFilterButton(() => false);
    };

    const countProjects = (description) => {
        if(description === 'ALL'){
            return projects.length;
        }

        return projects.filter((project) => project.description === description).length;
    }

    return(
        <div className={classes.container}>
            <div className={classes.headerContainer}>
                <div className={classes.buttonContainer}>
                    {
                        !filterButton && <button onClick={handleOpenFilter}>
                            <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" className={classes.open}>
                              <path d="
                                M5,15
                                L15,15
                                L12,12
                                M15,15
                                L12,18
                              " stroke="black" strokeWidth="2" fill="none" />
                            </svg>
                            {filter} <span>{countProjects(filter)}</span>
                        </button>
                    }
                    {
                        filterButton && <button onClick={handleCloseFilter}>
                            <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" className={classes.close}>
                              <path className="scribble" d="
                                M5,5
                                Q6,4 10,10
                                T15,20
                                T20,20
                                M20,5
                                Q19,6 15,10
                                T10,15
                                T5,20
                              " stroke="#000" strokeWidth="2" fill="none" />
                            </svg>
                            {t('work.close')}
                        </button>
                    }
                    {
                        filterButton && <div className={classes.filterContainer}>
                            <button onClick={() => handleSelect('ALL')}>{t('work.all')} <span>{countProjects('ALL')}</span></button>
                            <button onClick={() => handleSelect('STUDIO')}>STUDIO <span>{countProjects('STUDIO')}</span></button>
                            <button onClick={() => handleSelect('SOLUTIONS')}>SOLUTIONS <span>{countProjects('SOLUTIONS')}</span></button>
                        </div>
                    }
                </div>
                <div className={classes.aboutContainer}>
                    <p>
                    {t('work.description')}
                    </p>
                </div>
            </div>

            <div className={classes.projectsContainer}>
                

                {
                    filter === 'ALL' && projects.map((project) => (
                        <Link className={classes.projectElement} to={project.to} key={project.title}>
                            <p className={classes.title}>
                                {project.title}
                            </p>
                            <div className={classes.imgTextContainer}>
                                <div className={classes.imgContainer}>
                                    <img src={project.img} alt='TS MORALES'/>
                                </div>

                                <p className={classes.description}>
                                    {project.description}
                                </p>
                            </div>
                            <p className={classes.date}>
                                {project.year}
                            </p>
                        </Link>
                    ))
                }

                {
                    filter !== 'ALL' && projects.map((project) => {
                        if(project.description === filter){
                            return (
                                <Link className={classes.projectElement} to={project.to} key={project.title}>
                                    <p>
                                        {project.title}
                                    </p>
                                    <div className={classes.imgTextContainer}>
                                        <div className={classes.imgContainer}>
                                            <img src={project.img} alt='TS MORALES'/>
                                        </div>
        
                                        <p>
                                            {project.description}
                                        </p>
                                    </div>
                                    <p>
                                        {project.year}
                                    </p>
                                </Link>
                            )
                        }
                        return undefined;
                    })
                }
            </div>
        </div>
    )
}
