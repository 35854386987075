import React, { useEffect, useState, useRef, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-creative';
import { Autoplay, Pagination, Navigation, EffectCreative } from 'swiper/modules';
import gsap from 'gsap';
import classes from './SolutionsProjectSlider.module.css';
import follower from './CursorFollower.module.css';
import './SliderStyles.css';

import TranslationContext from '../hooks/TranslationProvider';


export default function SolutionsProjectSlider() {
    const { t } = useContext(TranslationContext);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [isHovered, setIsHovered] = useState(false);
    const circleRef = useRef(null);
    const svgRef = useRef(null);
    let windowWidth = window.innerWidth;
    // const handleMouseMove = (e) => {
    //     const rect = e.currentTarget.getBoundingClientRect();
    //     setMousePosition({
    //       x: e.clientX - rect.left,
    //       y: e.clientY - rect.top,
    //     });
    // };
    const [cursorVisible, setCursorVisible] = useState(false);
    const handleMouseMoveTest = (e) => {
        if (windowWidth >= 600) {
            gsap.to(circleRef.current, {
                opacity: 1,
                scale: 1,
                duration: 2,
                ease: 'power4.out',
            });
            gsap.to(circleRef.current, {
                x: e.clientX,
                y: e.clientY,
                duration: 0
            });
            // console.log('x', e.clientX, 'y', e.clientY);
        }
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMoveTest);
        // if a mouse move event is detected, set the cursor visible to true
        setCursorVisible(true);
        console.log('cursorVisible', cursorVisible);
        return () => {
            window.removeEventListener('mousemove', handleMouseMoveTest);
        };
    }, [cursorVisible]);

    
    const handleMouseMove = (e) => {
        if (windowWidth >= 600) {
            gsap.to(circleRef.current, {
                x: e.clientX,
                y: e.clientY,
                duration: 1, // Adjust this value for more or less delay
                ease: 'power4.out', // Smooth easing
            });
        }
    };

    useEffect(() => {
        // Attach the mousemove event listener to the window
        window.addEventListener('mousemove', handleMouseMove);
        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const animationDuration = 0.3;
    useEffect(() => {
        if (windowWidth >= 600) {
            if (isHovered) {
                gsap.to(circleRef.current, { scale: 3, duration: animationDuration });
                gsap.to(svgRef.current, { opacity: 1, scale: 1, duration: animationDuration });
            } else {
                gsap.to(circleRef.current, { scale: 1, duration: animationDuration });
                gsap.to(svgRef.current, { opacity: 0, scale: 0, duration: animationDuration });
            }
        }
    }, [isHovered]);

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
    };

    return (
        <section className={classes.sliderSection}>
            <div className={classes.ourProjectsContainer}>
                <div className={classes.ourProjects}>
                    <h1>{t('ourProjects.title')}</h1>
                </div>
                <div className={classes.bentoSlider}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Swiper
                        grabCursor={true}
                        effect={'creative'}
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: [0, 0, -400],
                            },
                            next: {
                                translate: ['100%', 0, 0],
                            },
                        }}
                        // creativeEffect={{
                        //     prev: {
                        //         shadow: true,
                        //         translate: [0, 0, -800],
                        //         rotate: [180, 0, 0],
                        //     },
                        //     next: {
                        //         shadow: true,
                        //         translate: [0, 0, -800],
                        //         rotate: [-180, 0, 0],
                        //     },
                        // }}

                        modules={[Autoplay, Pagination, Navigation, EffectCreative]} // Add modules as needed
                        spaceBetween={0}
                        slidesPerView={1}
                        navigation
                        pagination={pagination}
                        autoplay={{
                            delay: 7500,
                            disableOnInteraction: false,
                        }}
                        // speed={1000}  // Transition duration (in milliseconds)
                        loop={true}
                        >
                        <SwiperSlide>
                            <div className={classes.project}>
                                <div className={classes.projectText}>{t('ourProjects.sysnot')}</div>
                                <a className={classes.linkStyle} href='/work/sysnot' target='_blank'>
                                    <img src="/images/project_slider/sysnot-browser.png" alt="Proyecto Sysnot" />
                                </a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={classes.projectText}>{t('ourProjects.cerm')}</div>
                            <a className={classes.linkStyle} href='/work/cerm' target='_blank'>
                                <img src="/images/project_slider/cerm-browser.png" alt="Proyecto CERM Automotive Service" />
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={classes.projectText}>{t('ourProjects.mercyfit')}</div>
                            <a className={classes.linkStyle} href='/work/mercyfit' target='_blank'>
                                <img src="/images/project_slider/mercyfit-browser.png" alt="Proyecto MercyFit" />
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={classes.projectTextInverted}>{t('ourProjects.cinthyasvanity')}</div>
                            <a className={classes.linkStyle} href='/work/cinthyasvanity' target='_blank'>
                                <img src="/images/project_slider/cinthyasvanity-browser.png" alt="Proyecto Cinthya's Vanity" />
                            </a>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            { // IM NOT SURE WHY IT DOESNT UPDATE DYNAMICALLY, I HAVE TO RELOAD THE SITE FOR CHANGES TO SHOW
                windowWidth >= 600 && cursorVisible &&
                <div
                    className={follower.followerCircle}
                    ref={circleRef}
                    style={{ top: mousePosition.y, left: mousePosition.x, }}
                >
                    <img
                        ref={svgRef}
                        src={'/images/icons/link-to.svg'}
                        alt="Link to"
                        className={follower.linkToSvg}
                    />
                </div>
            }
        </section>
    )
}
