// This file centralizes all project data
const projects = {
  tsmorales: {
    id: 'tsmorales',
    title: 'TS MORALES',
    year: 2024,
    description: 'STUDIO',
    translationKey: 'tsmorales',
    videoSource: '/video/TSMORALES.mp4',
    imageSource: '/images/tsmorales/ts7.png',
    photos: [
      '/images/tsmorales/ts1.jpeg',
      '/images/tsmorales/ts2.jpeg',
      '/images/tsmorales/ts4.jpeg',
      '/images/tsmorales/ts3.jpeg',
      '/images/tsmorales/ts5.png',
      '/images/tsmorales/ts6.png',
      '/images/tsmorales/ts7.png'
    ],
    nextProject: 'cerm',
    liveUrl: 'http://tsmorales.netlify.app/',
    hasHorizontalScroll: true
  },
  sysnot: {
    id: 'sysnot',
    title: 'SYSNOT',
    year: 2024,
    description: 'SOLUTIONS',
    translationKey: 'sysnot',
    videoSource: '/video/sysnot.mp4',
    imageSource: '/images/studio/sys2.png',
    photos: [
      '/images/sysnot/sys1.jpeg',
      '/images/sysnot/sys2.png',
      '/images/sysnot/sys3.png',
      '/images/sysnot/sys4.png',
      '/images/sysnot/sys5.png',
      '/images/sysnot/sys6.png'
    ],
    nextProject: 'tsmorales',
    liveUrl: null, // If there's no live URL
    hasHorizontalScroll: true
  },
  cerm: {
    id: 'cerm',
    title: 'CERM-AUTO',
    year: 2023,
    description: 'SOLUTIONS',
    translationKey: 'cerm',
    videoSource: '/video/CERM.mp4',
    imageSource: '/images/cerm/cerm5.png',
    photos: [
      '/images/cerm/cerm1.jpeg',
      '/images/cerm/cerm2.jpeg',
      '/images/cerm/cerm3.jpeg',
      '/images/cerm/cerm4.jpeg',
      '/images/cerm/cerm5.png',
      '/images/cerm/cerm6.png',
      '/images/cerm/cerm7.png',
      '/images/cerm/cerm8.png'
    ],
    nextProject: 'mercyfit',
    liveUrl: 'https://cerm-auto.com',
    hasHorizontalScroll: true
  },
  mercyfit: {
    id: 'mercyfit',
    title: 'MercyFit',
    year: 2023,
    description: 'SOLUTIONS',
    translationKey: 'mercyfit',
    videoSource: '/video/mercyfit.mp4',
    imageSource: '/images/mercy/mercyfit-browser.png',
    photos: [],
    nextProject: 'cinthyasvanity',
    liveUrl: 'https://mercyfit.com.mx/',
    hasHorizontalScroll: false
  },
  notaria137: {
    id: 'notaria137',
    title: 'Notaría 137',
    year: 2024,
    description: 'STUDIO',
    translationKey: 'notaria137',
    videoSource: '/video/notaria137.mp4',
    imageSource: '/images/notaria137/notaria137-browser.png',
    photos: [],
    nextProject: 'sysnot',
    liveUrl: 'https://notaria137.mx/',
    hasHorizontalScroll: false
  },
  cinthyasvanity: {
    id: 'cinthyasvanity',
    title: "Cinthya's Vanity",
    year: 2025,
    description: 'SOLUTIONS',
    translationKey: 'cinthyasvanity',
    videoSource: '/video/cinthyasvanityreduced.mp4',
    imageSource: '/images/cinthyasvanity/cinthyasvanity-browser.png',
    photos: [],
    nextProject: 'notaria137',
    liveUrl: 'https://cinthyasvanity.com/',
    hasHorizontalScroll: false
  }
};

export default projects; 
