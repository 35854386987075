import { useEffect, useRef, useContext } from 'react';
import { gsap } from 'gsap';
import { Link } from "react-router-dom";
import TranslationContext from '../hooks/TranslationProvider';


const StudioWorkSection = () => {
  const { t } = useContext(TranslationContext);
  const workItems = useRef([]);

  const featuredProjects = [
    {
        title : 'TS MORALES',
        year : 2024,
        img : '/images/studio/tsmorales.png',
        to: '/work/tsmorales'
    },
    {
        title : 'NOTARÍA 137',
        year : 2024,
        img : '/images/notaria137/notaria137-browser.png',
        to: '/work/notaria137'
    },
    {
        title : t('studio.work.allWork'),
        year : t('studio.work.since'),
        img : '/images/studio/tsmorales.png',
        to: '/work'
    },
]

  useEffect(() => {
    const handleMouseMove = (event) => {
      workItems.current.forEach((item) => {
        const img = item.querySelector('img');
        const rect = item.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        // Set the image position relative to the cursor within the container
        const moveX = x - (img.offsetWidth / 2) * 1.75 ; // Center the image on the X axis
        const moveY = y - (img.offsetHeight / 2); // Center the image on the Y axis

        gsap.to(img, {
          x: moveX,
          y: moveY,
          duration: 0.3,
          ease: 'power2.out'
        });
      });
    };

    workItems.current.forEach((item) => {
      item.addEventListener('mousemove', handleMouseMove);
    });

    // Cleanup function: remove event listeners
    return () => {
      workItems.current.forEach((item) => {
        if (item) {
          item.removeEventListener('mousemove', handleMouseMove);
        }
      });
    };
  }, []);

  return (
    <div className="featured-work">
        <h1>{t('studio.work.title')}</h1>
      {
        featuredProjects.map((project, index) => 
            <Link
                className="work-item"
                ref={(el) => (workItems.current[index] = el)}
                to={project.to || null} 
            >
                <h3>{project.title}</h3>
                <img src={project.img} alt={project.title} />
                <h3>{project.year}</h3>
            </Link>
        )
      }
      {/* Add more work items as needed */}
    </div>
  );
};

export default StudioWorkSection;
